import axios from "axios";
import { setCurrentUser } from "./authActions";
import {
  // LISTTE ANNONCE
  GET_BIEN_ALL,
  GET_BIEN_SEARCH,
  RESET_BIEN_SEARCH,
  RESET_BIEN_SEARCH_BRUT,
  RESET_BIEN_SEARCH_WALLET,
  UPDATE_STATUT_QUALIFICATION,
  SEND_ANNONCE_A_QUALIFIER,
  GET_NOMBRE_ANNONCE,
  GET_NOMBRE_ANNONCE_BRUT,
  GET_NOMBRE_ANNONCE_WALLET,
  UPDATE_CRITERE_RECHERCHE,
  UPDATE_CRITERE_RECHERCHE_REQSTART,
  //
  UPDATE_LISTE_BIEN_SIMILAIRE_VENTE,
  UPDATE_LISTE_BIEN_SIMILAIRE_LOCATION,
  // GET STAT
  GET_COUNT_TYPE_BIEN,
  GET_COUNT_SOURCE,
  GET_COUNT_SOURCE_24,
  GET_COUNT_STATUT_QUALIFICATION,
  GET_COUNT_TODAY,

  // GET AND UPDATE DATABASE BIEN
  GET_DATA_BIEN,
  RESET_DATA_BIEN,
  UPDATE_DATA_BIEN,
  UPDATE_DATA_BIEN_NOT_YET,
  UPDATE_DATABIEN_FROM_WALLET,
  GET_ANNONCE_NON_INVESTISSEUR,

  // GET DATA WALLET
  GET_ANNONCE_WALLET,

  // UPDATE ADRESSE BIEN
  UPDATE_ADRESSE_BIEN,
  GET_BIEN_SIMILAIRE_VENTE,
  GET_BIEN_SIMILAIRE_LOCATION,
  GET_BIEN_NOTAIRE,
  ADD_BIEN,
  UPDATE_USER_COLORS,
} from "../actions/types";
const { APIServerUrl, APIFrontUrl } = require("../shared/values/environnement");

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

export const addannoncevues = (idUser, idAnnonce) => (dispatch) => {
  console.log("dkhalee");
  axios
    .put(APIServerUrl + "/api/users/updatelist/" + idUser + "/" + idAnnonce)
    .then((res) => {
      console.log("reees", res);
      if (res.status == 200) {
        var user = res.data.users;
        user["id"] = res.data.users._id;
        delete res.data.users._id;
        console.log("jew");
        dispatch(setCurrentUser(res.data.users));
      }
      return res;
    });
};

export const getUserliste = (idUser) => (dispatch) => {
  axios.get(APIServerUrl + "/api/users/user/" + idUser).then((res) => {
    console.log("reees", res);
    if (res.status == 200) {
      var user = res.data;
      user["id"] = res.data._id;
      delete res.data._id;
      console.log("jew", user);
      dispatch(setCurrentUser(user));
    }
    return res;
  });
};

// Get count by Type bien
export const getCountByTypeBien = (connectedUserRole) => (dispatch) => {
  if ([0].includes(parseInt(connectedUserRole))) {
    axios
      .get(APIServerUrl + "/api/bien/count-by-type-bien", {
        cancelToken: source.token,
      })
      .then((res) => {
        dispatch({
          type: GET_COUNT_TYPE_BIEN,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("getCountByTypeBien annulée", err.message);
        } else {
          dispatch({
            type: GET_COUNT_TYPE_BIEN,
            payload: err,
          });
        }
      });
  } else {
    return false;
  }
};

// Get count by Source
export const getCountBySource = () => (dispatch) => {
  axios
    .get(APIServerUrl + "/api/bien/count-by-source", {
      cancelToken: source.token,
    })
    .then((res) => {
      dispatch({
        type: GET_COUNT_SOURCE,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.log("getCountBySource annulée", err.message);
      } else {
        dispatch({
          type: GET_COUNT_SOURCE,
          payload: err,
        });
      }
    });
};

// Get count by Source last 24
export const getCountBySource24 = () => {
  return axios.get(APIServerUrl + "/api/bien/count-by-source-lastday", {
    cancelToken: source.token,
  });
  // .then((res) => {
  //   console.log(res)
  //   dispatch({
  //     type: GET_COUNT_SOURCE_24,
  //     payload: res.data,
  //   });
  // })
};

// Get count by Statut qualification
export const getCountByStatutQualification = () => (dispatch) => {
  axios
    .get(APIServerUrl + "/api/bien/count-by-statut-qualification", {
      cancelToken: source.token,
    })
    .then((res) => {
      dispatch({
        type: GET_COUNT_STATUT_QUALIFICATION,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.log("getCountByStatutQualification annulée", err.message);
      } else {
        dispatch({
          type: GET_COUNT_STATUT_QUALIFICATION,
          payload: err,
        });
      }
    });
};

// Get count today
export const getCountToday = () => (dispatch) => {
  axios
    .get(APIServerUrl + "/api/bien/count-today", {
      cancelToken: source.token,
    })
    .then((res) => {
      dispatch({
        type: GET_COUNT_TODAY,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.log("getCountByStatutQualification annulée", err.message);
      } else {
        dispatch({
          type: GET_COUNT_TODAY,
          payload: err,
        });
      }
    });
};

// Get contour commune
export const getAllBien = () => (dispatch) => {
  axios
    .get(APIServerUrl + "/api/bien/all")
    .then((res) => {
      dispatch({
        type: GET_BIEN_ALL,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_BIEN_ALL,
        payload: err,
      })
    );
};

// Get All annonces
// export const getAllAnonces = (limit) => dispatch => {

//   axios.get(APIServerUrl + '/api/annonce/getallannonces/'+limit).then(response =>{
//     dispatch({
//       type:GET_ANNONCE_NON_INVESTISSEUR,
//       payload: response.data
//     })
//   }).catch(err =>dispatch({
//     type:GET_ANNONCE_NON_INVESTISSEUR,
//     payload: err
//   }))
// }

export const getAllAnonces =
  (
    reqStart,
    reqNumber,
    paramIdAnnonce,
    paramDepartement,
    paramCommune,
    paramTypeBien,
    paramTravaux,
    paramTypeOffer,
    paramStatutQualification,
    paramBudgetMin,
    paramBudgetMax,
    paramPrixM2Min,
    paramPrixM2Max,
    paramRendementBrutMin,
    paramRendementBrutMax,
    paramPlusValueMin,
    paramPlusValueMax,
    paramSurfaceMin,
    paramSurfaceMax,
    paramNbePieceMin,
    paramNbePieceMax,
    sortAttribute
  ) =>
  (dispatch) => {
    source.cancel("Opérations annulées par getBien");
    console.log(sortAttribute);
    return axios
      .post(APIServerUrl + "/api/annonce/search-brut", {
        reqStart: reqStart,
        reqNumber: reqNumber,
        paramIdAnnonce: paramIdAnnonce,
        paramDepartement: paramDepartement,
        paramCommune: paramCommune,
        paramTypeBien: paramTypeBien,
        paramTravaux: paramTravaux,
        paramTypeOffer: paramTypeOffer,
        paramStatutQualification: paramStatutQualification,
        paramBudgetMin: paramBudgetMin,
        paramBudgetMax: paramBudgetMax,
        paramPrixM2Min: paramPrixM2Min,
        paramPrixM2Max: paramPrixM2Max,
        paramRendementBrutMin: paramRendementBrutMin,
        paramRendementBrutMax: paramRendementBrutMax,
        paramPlusValueMin: paramPlusValueMin,
        paramPlusValueMax: paramPlusValueMax,
        paramSurfaceMin: paramSurfaceMin,
        paramSurfaceMax: paramSurfaceMax,
        paramNbePieceMin: paramNbePieceMin,
        paramNbePieceMax: paramNbePieceMax,
        sortAttribute: sortAttribute,
      })
      .then((res) => {
        console.log("annonce", res.data);
        dispatch({
          type: GET_ANNONCE_NON_INVESTISSEUR,
          payload: res.data,
        });
        return 1;
      })
      .catch((err) => {
        dispatch({
          type: GET_ANNONCE_NON_INVESTISSEUR,
          payload: [],
        });
        console.log("Action | Bien | getBien : ", err);
      });
  };
export const getAllAnoncesWallet =
  (
    id_wallet,
    reqStart,
    reqNumber,
    paramIdAnnonce,
    paramDepartement,
    paramCommune,
    paramTypeBien,
    paramTravaux,
    paramTypeOffer,
    paramStatutQualification,
    paramBudgetMin,
    paramBudgetMax,
    paramPrixM2Min,
    paramPrixM2Max,
    paramRendementBrutMin,
    paramRendementBrutMax,
    paramPlusValueMin,
    paramPlusValueMax,
    paramSurfaceMin,
    paramSurfaceMax,
    paramNbePieceMin,
    paramNbePieceMax,
    sortAttribute
  ) =>
  (dispatch) => {
    source.cancel("Opérations annulées par getBien");
    console.log(sortAttribute);
    return axios
      .post(APIServerUrl + "/api/bien/wallet1/" + id_wallet, {
        reqStart: reqStart,
        reqNumber: reqNumber,
        paramIdAnnonce: paramIdAnnonce,
        paramDepartement: paramDepartement,
        paramCommune: paramCommune,
        paramTypeBien: paramTypeBien,
        paramTravaux: paramTravaux,
        paramTypeOffer: paramTypeOffer,
        paramStatutQualification: paramStatutQualification,
        paramBudgetMin: paramBudgetMin,
        paramBudgetMax: paramBudgetMax,
        paramPrixM2Min: paramPrixM2Min,
        paramPrixM2Max: paramPrixM2Max,
        paramRendementBrutMin: paramRendementBrutMin,
        paramRendementBrutMax: paramRendementBrutMax,
        paramPlusValueMin: paramPlusValueMin,
        paramPlusValueMax: paramPlusValueMax,
        paramSurfaceMin: paramSurfaceMin,
        paramSurfaceMax: paramSurfaceMax,
        paramNbePieceMin: paramNbePieceMin,
        paramNbePieceMax: paramNbePieceMax,
        sortAttribute: sortAttribute,
      })
      .then((res) => {
        dispatch({
          type: GET_ANNONCE_WALLET,
          payload: res.data,
        });
        return 1;
      })
      .catch((err) => {
        dispatch({
          type: GET_ANNONCE_WALLET,
          payload: [],
        });
        console.log("Action | Bien | getBien : ", err);
      });
  };
export const getNombreBienWallet =
  (
    id_wallet,
    reqStart,
    reqNumber,
    paramIdAnnonce,
    paramDepartement,
    paramCommune,
    paramTypeBien,
    paramTravaux,
    paramTypeOffer,
    paramStatutQualification,
    paramBudgetMin,
    paramBudgetMax,
    paramPrixM2Min,
    paramPrixM2Max,
    paramRendementBrutMin,
    paramRendementBrutMax,
    paramPlusValueMin,
    paramPlusValueMax,
    paramSurfaceMin,
    paramSurfaceMax,
    paramNbePieceMin,
    paramNbePieceMax,
    sortAttribute
  ) =>
  (dispatch) => {
    source.cancel("Opérations annulées par getBien");
    console.log(sortAttribute);
    return axios
      .post(APIServerUrl + "/api/bien/wallet-count/" + id_wallet, {
        reqStart: reqStart,
        reqNumber: reqNumber,
        paramIdAnnonce: paramIdAnnonce,
        paramDepartement: paramDepartement,
        paramCommune: paramCommune,
        paramTypeBien: paramTypeBien,
        paramTravaux: paramTravaux,
        paramTypeOffer: paramTypeOffer,
        paramStatutQualification: paramStatutQualification,
        paramBudgetMin: paramBudgetMin,
        paramBudgetMax: paramBudgetMax,
        paramPrixM2Min: paramPrixM2Min,
        paramPrixM2Max: paramPrixM2Max,
        paramRendementBrutMin: paramRendementBrutMin,
        paramRendementBrutMax: paramRendementBrutMax,
        paramPlusValueMin: paramPlusValueMin,
        paramPlusValueMax: paramPlusValueMax,
        paramSurfaceMin: paramSurfaceMin,
        paramSurfaceMax: paramSurfaceMax,
        paramNbePieceMin: paramNbePieceMin,
        paramNbePieceMax: paramNbePieceMax,
        sortAttribute: sortAttribute,
      })
      .then((res) => {
        dispatch({
          type: GET_NOMBRE_ANNONCE_WALLET,
          payload: res.data === "" ? 0 : res.data,
        });
        return 1;
      })
      .catch((err) => {
        dispatch({
          type: GET_NOMBRE_ANNONCE_WALLET,
          payload: 0,
        });
        console.log("Action | Bien | getNombreBien ", err);
      });
  };

// Get data d'un bien
export const getDataBien = (_id) => (dispatch) => {
  return axios
    .get(APIServerUrl + "/api/bien/data/" + _id)
    .then((res) => {
      var data = res.data[0];

      if (data.fiscalite === undefined) {
        data["fiscalite"] = {
          revenu_foncier_annuel: 0,
          option_fiscale: 0,
          nbe_annee_deficit_foncier: 0,
          montant_amortissement_bien_annuel: 0,
          charges_interets_moyen_5_ans: 0,
        };
      }

      dispatch({
        type: GET_DATA_BIEN,
        payload: data,
      });

      return data;
    })
    .catch((err) =>
      dispatch({
        type: GET_DATA_BIEN,
        payload: err,
      })
    );
};

// Update data d'un bien
export const updateDataBien = (dataBien) => (dispatch) => {
  axios
    .post(APIServerUrl + "/api/bien/update", dataBien)
    .then((res) => {
      dispatch({
        type: UPDATE_DATA_BIEN,
        payload: dataBien,
      });
    })
    .catch((err) =>
      dispatch({
        type: UPDATE_DATA_BIEN,
        payload: err,
      })
    );
};

// Indiquer que le bien n'est pas sauvegardé
export const updateDataBienNotYet = () => {
  return {
    type: UPDATE_DATA_BIEN_NOT_YET,
    payload: 0,
  };
};

// Reset bien
export const resetBien = () => {
  return {
    type: RESET_DATA_BIEN,
    payload: {},
  };
};

// Reset liste bien
export const resetListeBien = () => {
  return {
    type: RESET_BIEN_SEARCH,
    payload: { reset_liste_bien: true },
  };
};

export const resetListeBienBrut = () => {
  return {
    type: RESET_BIEN_SEARCH_BRUT,
    payload: { reset_liste_bien_brut: true },
  };
};
export const resetListeBienWallet = () => {
  return {
    type: RESET_BIEN_SEARCH_WALLET,
    payload: { reset_liste_bien_wallet: true },
  };
};
// Send annonce à qualifier
export const sendAnnonceAQualifier = (annonce) => (dispatch) => {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/jsoncharset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  };
  axios
    .post(
      "http://164.132.105.186:5002/send_annone_a_qualifier",
      annonce,
      axiosConfig
    )
    .then((res) => {
      dispatch({
        type: SEND_ANNONCE_A_QUALIFIER,
        payload: { sendAnnonceAQualifier: "True", data: annonce },
      });
    })
    .catch((err) =>
      dispatch({
        type: SEND_ANNONCE_A_QUALIFIER,
        payload: {
          /*sendAnnonceAQualifier: 'False', data: annonce,*/ error: err,
        },
      })
    );
};

// Get bien selon paramètres de recherche
export const getBien =
  (
    reqStart,
    reqNumber,
    paramIdAnnonce,
    paramDepartement,
    paramCommune,
    paramTypeBien,
    paramTravaux,
    paramTypeOffer,
    paramStatutQualification,
    paramBudgetMin,
    paramBudgetMax,
    paramPrixM2Min,
    paramPrixM2Max,
    paramRendementBrutMin,
    paramRendementBrutMax,
    paramPlusValueMin,
    paramPlusValueMax,
    paramSurfaceMin,
    paramSurfaceMax,
    paramNbePieceMin,
    paramNbePieceMax,
    sortAttribute
  ) =>
  (dispatch) => {
    source.cancel("Opérations annulées par getBien");

    return axios
      .post(APIServerUrl + "/api/bien/search", {
        reqStart: reqStart,
        reqNumber: reqNumber,
        paramIdAnnonce: paramIdAnnonce,
        paramDepartement: paramDepartement,
        paramCommune: paramCommune,
        paramTypeBien: paramTypeBien,
        paramTravaux: paramTravaux,
        paramTypeOffer: paramTypeOffer,
        paramStatutQualification: paramStatutQualification,
        paramBudgetMin: paramBudgetMin,
        paramBudgetMax: paramBudgetMax,
        paramPrixM2Min: paramPrixM2Min,
        paramPrixM2Max: paramPrixM2Max,
        paramRendementBrutMin: paramRendementBrutMin,
        paramRendementBrutMax: paramRendementBrutMax,
        paramPlusValueMin: paramPlusValueMin,
        paramPlusValueMax: paramPlusValueMax,
        paramSurfaceMin: paramSurfaceMin,
        paramSurfaceMax: paramSurfaceMax,
        paramNbePieceMin: paramNbePieceMin,
        paramNbePieceMax: paramNbePieceMax,
        sortAttribute: sortAttribute,
      })
      .then((res) => {
        dispatch({
          type: GET_BIEN_SEARCH,
          payload: res.data,
        });
        return 1;
      })
      .catch((err) => {
        dispatch({
          type: GET_BIEN_SEARCH,
          payload: [],
        });
        console.log("Action | Bien | getBien : ", err);
      });
  };

// Get de le nombre de biens selon paramètres de recherche
export const getNombreBien =
  (
    paramIdAnnonce,
    paramDepartement,
    paramCommune,
    paramTypeBien,
    paramTravaux,
    paramTypeOffer,
    paramStatutQualification,
    paramBudgetMin,
    paramBudgetMax,
    paramPrixM2Min,
    paramPrixM2Max,
    paramRendementBrutMin,
    paramRendementBrutMax,
    paramPlusValueMin,
    paramPlusValueMax,
    paramSurfaceMin,
    paramSurfaceMax,
    paramNbePieceMin,
    paramNbePieceMax
  ) =>
  (dispatch) => {
    return axios
      .post(APIServerUrl + "/api/bien/search-count", {
        paramIdAnnonce: paramIdAnnonce,
        paramDepartement: paramDepartement,
        paramCommune: paramCommune,
        paramTypeBien: paramTypeBien,
        paramTravaux: paramTravaux,
        paramTypeOffer: paramTypeOffer,
        paramStatutQualification: paramStatutQualification,
        paramBudgetMin: paramBudgetMin,
        paramBudgetMax: paramBudgetMax,
        paramPrixM2Min: paramPrixM2Min,
        paramPrixM2Max: paramPrixM2Max,
        paramRendementBrutMin: paramRendementBrutMin,
        paramRendementBrutMax: paramRendementBrutMax,
        paramPlusValueMin: paramPlusValueMin,
        paramPlusValueMax: paramPlusValueMax,
        paramSurfaceMin: paramSurfaceMin,
        paramSurfaceMax: paramSurfaceMax,
        paramNbePieceMin: paramNbePieceMin,
        paramNbePieceMax: paramNbePieceMax,
      })
      .then((res) => {
        dispatch({
          type: GET_NOMBRE_ANNONCE,
          payload: res.data === "" ? 0 : res.data,
        });
        return 1;
      })
      .catch((err) => {
        dispatch({
          type: GET_NOMBRE_ANNONCE,
          payload: 0,
        });
        console.log("Action | Bien | getNombreBien ", err);
      });
  };
// Get de le nombre de biens bruts selon paramètres de recherche

export const getNombreBienBrut =
  (
    paramIdAnnonce,
    paramDepartement,
    paramCommune,
    paramTypeBien,
    paramTravaux,
    paramTypeOffer,
    paramStatutQualification,
    paramBudgetMin,
    paramBudgetMax,
    paramPrixM2Min,
    paramPrixM2Max,
    paramRendementBrutMin,
    paramRendementBrutMax,
    paramPlusValueMin,
    paramPlusValueMax,
    paramSurfaceMin,
    paramSurfaceMax,
    paramNbePieceMin,
    paramNbePieceMax
  ) =>
  (dispatch) => {
    return axios
      .post(APIServerUrl + "/api/annonce/search-count-brut", {
        paramIdAnnonce: paramIdAnnonce,
        paramDepartement: paramDepartement,
        paramCommune: paramCommune,
        paramTypeBien: paramTypeBien,
        paramTravaux: paramTravaux,
        paramTypeOffer: paramTypeOffer,
        paramStatutQualification: paramStatutQualification,
        paramBudgetMin: paramBudgetMin,
        paramBudgetMax: paramBudgetMax,
        paramPrixM2Min: paramPrixM2Min,
        paramPrixM2Max: paramPrixM2Max,
        paramRendementBrutMin: paramRendementBrutMin,
        paramRendementBrutMax: paramRendementBrutMax,
        paramPlusValueMin: paramPlusValueMin,
        paramPlusValueMax: paramPlusValueMax,
        paramSurfaceMin: paramSurfaceMin,
        paramSurfaceMax: paramSurfaceMax,
        paramNbePieceMin: paramNbePieceMin,
        paramNbePieceMax: paramNbePieceMax,
      })
      .then((res) => {
        dispatch({
          type: GET_NOMBRE_ANNONCE_BRUT,
          payload: res.data === "" ? 0 : res.data,
        });
        return 1;
      })
      .catch((err) => {
        dispatch({
          type: GET_NOMBRE_ANNONCE_BRUT,
          payload: 0,
        });
        console.log("Action | Bien | getNombreBien ", err);
      });
  };

// Get liste d'annonces similaires
// Get contour commune
export const getBienSimilaireVente =
  (codeInsee, surface, nbePiece, typeBien, typeTransaction, idAnnonce) =>
  (dispatch) => {
    return axios
      .get(
        APIServerUrl +
          "/api/bien/similar/" +
          codeInsee +
          "/" +
          surface +
          "/" +
          nbePiece +
          "/" +
          typeBien +
          "/" +
          typeTransaction
      )
      .then((res) => {
        let result = res.data.filter((bien) => bien._id !== idAnnonce);
        dispatch({
          type: GET_BIEN_SIMILAIRE_VENTE,
          payload: result,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_BIEN_SIMILAIRE_VENTE,
          payload: err,
        })
      );
  };

export const updateListBienSimilaireVente = (Liste) => (dispatch) => {
  return dispatch({
    type: UPDATE_LISTE_BIEN_SIMILAIRE_VENTE,
    payload: Liste,
  });
};

export const updateListBienSimilaireLocation = (Liste) => (dispatch) => {
  return dispatch({
    type: UPDATE_LISTE_BIEN_SIMILAIRE_LOCATION,
    payload: Liste,
  });
};

// Get liste d'annonces similaires
// Get contour commune
export const getBienSimilaireLocation =
  (codeInsee, surface, nbePiece, typeBien, typeTransaction, idAnnonce) =>
  (dispatch) => {
    return axios
      .get(
        APIServerUrl +
          "/api/annonce/similar/" +
          codeInsee +
          "/" +
          surface +
          "/" +
          nbePiece +
          "/" +
          typeBien +
          "/" +
          typeTransaction
      )
      .then((res) => {
        let result = res.data.filter((bien) => bien._id !== idAnnonce);

        dispatch({
          type: GET_BIEN_SIMILAIRE_LOCATION,
          payload: result,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_BIEN_SIMILAIRE_LOCATION,
          payload: err,
        })
      );
  };

//Get Bien notaire
export const getBienNotaire =
  (nbePiece, surface, typeBien, code_postal) => (dispatch) => {
    return axios
      .get(
        APIServerUrl +
          "/api/annonce/notaire/" +
          surface +
          "/" +
          nbePiece +
          "/" +
          typeBien +
          "/" +
          code_postal
      )
      .then((res) => {
        dispatch({
          type: GET_BIEN_NOTAIRE,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_BIEN_NOTAIRE,
          payload: err,
        })
      );
  };

// Get Rendement moyen par code_insee et type_bien
export const getRendementMoyen = (codeInsee) => {
  return axios
    .get(APIServerUrl + "/api/bien/rendement_moyen_type_bien/" + codeInsee)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// Get Rendement moyen par code_insee et type_bien
export const getRendementMoyenNbePieceInf6 = (codeInsee) => {
  return axios
    .get(
      APIServerUrl +
        "/api/bien/rendement_moyen_type_bien_nbe_piece_inf6/" +
        codeInsee
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// Get Rendement moyen par code_insee et type_bien
export const getRendementMoyenNbePieceSup6 = (codeInsee) => {
  return axios
    .get(
      APIServerUrl +
        "/api/bien/rendement_moyen_type_bien_nbe_piece_sup6/" +
        codeInsee
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// Update Statut qualification
export const updateStatutQualification = (statutAnnonce) => (dispatch) => {
  axios
    .post(APIServerUrl + "/api/bien/statut-qualification", statutAnnonce)
    .then((res) => {
      dispatch({
        type: UPDATE_STATUT_QUALIFICATION,
        payload: { statusUpdateQualification: "True" },
      });
    })
    .catch((err) =>
      dispatch({
        type: UPDATE_STATUT_QUALIFICATION,
        payload: { statusUpdateQualification: "False", error: err },
      })
    );
};

// Update Adresse Bien
export const updateAdresseBien = (adresseBien) => {
  return {
    type: UPDATE_ADRESSE_BIEN,
    payload: adresseBien,
  };
};

// Update Critères de recherche
export const updateCritereRecherche = (critereRecherche) => {
  return {
    type: UPDATE_CRITERE_RECHERCHE,
    payload: critereRecherche,
  };
};

// Update Critères de recherche
export const updateCritereRechercheReqStart = (reqStart) => {
  return {
    //
    type: UPDATE_CRITERE_RECHERCHE_REQSTART,
    payload: reqStart,
  };
};

// update crieter

// Add nouveau Bien
export const addBien = (bien) => (dispatch) => {
  axios
    .post(APIServerUrl + "/api/bien/add", bien)
    .then((res) => {
      dispatch({
        type: ADD_BIEN,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: ADD_BIEN,
        payload: err,
      })
    );
};

// Ajout d'un Bien à un portefeuille

// export const addBienPortefeuille2 = (bien) =>

export const addBienPortefeuille = (bien) => {
  console.log("bien", bien);
  if (isNaN(bien.etage)) {
    bien.etage = parseInt(bien.etage);
  }

  if (isNaN(bien.qualification_robot.vente.plus_value_net)) {
    bien.qualification_robot.vente.plus_value_net = 0;
  } else {
    bien.qualification_robot.vente.plus_value_net = Math.abs(
      bien.qualification_robot.vente.plus_value_net
    );
  }
  if (isNaN(bien.qualification_robot.vente.plus_value_brut)) {
    bien.qualification_robot.vente.plus_value_brut = 0;
  } else {
    bien.qualification_robot.vente.plus_value_brut = Math.abs(
      bien.qualification_robot.vente.plus_value_brut
    );
  }

  console.log("bien", bien);
  return axios
    .post(APIServerUrl + "/api/bien/wallet/add", bien)

    .then((res) => {
      console.log("dkhal");
      if (res.data._id) {
        console.log("window.location.href", window.location);
        console.log("res.data._id", res.data);
        window.location.href =
          window.location.origin +
          "/in/mon-portefeuille/" +
          res.data.ajout_id_gestionnaire +
          "/annonce/edit2/" +
          res.data.ajout_id_annonce;
      }
      console.log(res.data);

      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addBienPortefeuille2 = (bien) => {
  console.log(bien, "api_bien");
  return axios
    .post(APIServerUrl + "/api/bien/wallet/add", bien)
    .then((res) => {
      if (res.data._id && bien.user != "client") {
        // window.location.href =
        //   window.location.origin + "/in/gestionnaire/liste-gestionnaire";
      }
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// Update d'un Bien à un portefeuille
export const updateBienPortefeuille = (bien) => {
  console.log("bien", bien);
  return axios
    .post(APIServerUrl + "/api/bien/wallet/update", bien)
    .then((res) => {
      console.log('res2',res)
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// Mise à jour du dossier-detaille d'un bien
export const updateDossierDetailleBien = (bien) => {
  return axios
    .post(APIServerUrl + "/api/bien/dossier-detaille", bien)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// Vérifie l'existence d'un bien dans un portefeuille
export const annonceInWallet = (id_wallet, id_annonce) => {
  return axios
    .get(
      APIServerUrl +
        "/api/bien/wallet/" +
        id_wallet +
        "/bien/" +
        id_annonce +
        "/option/check"
    )
    .then((res) => {
      console.log(id_wallet, id_annonce, "returnnnn", res.data);
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// Get data d'un bien à partir d'un portefeuille
export const getDataBienFromWallet = (id_wallet, id_annonce) => (dispatch) => {
  console.log("houni dkhal");
  return axios
    .get(
      APIServerUrl +
        "/api/bien/wallet/" +
        id_wallet +
        "/bien/" +
        id_annonce +
        "/option/data"
    )
    .then((res) => {
      dispatch({
        type: UPDATE_DATABIEN_FROM_WALLET,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

// Get data d'un bien à partir d'un portefeuille
export const deleteBienFromWallet = (id_annonce) => {
  console.log("id_annonce", id_annonce);
  return axios
    .post(
      APIServerUrl + "/api/bien/wallet/bien/delete/" + id_annonce.id_wallet,
      id_annonce
    )
    .then((res) => {
      console.log("reeeees", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const deleteBien = (id_annonce) => {
  return axios
    .post(APIServerUrl + "/api/bien/bien/delete/", id_annonce)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

// Get data d'un bien à partir d'un portefeuille
export const getListeBienFromWallet = (id_wallet, role) => {
  return axios
    .post(APIServerUrl + "/api/bien/wallet/" + id_wallet + "/" + role, {
      role: role,
    })
    .then((res) => {
      console.log("wallet");
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const convertimg = async (img) => {
  let res = await axios.post(APIServerUrl + "/api/bien/converimgtobase/", {
    url_photo: img,
  });
  return res.data;
};

//nouvelles services (portefeuille)
export const getannonceportefeuille = (
  id_wallet,
  pagination,
  sortAttribute
) => {
  return axios
    .get(
      APIServerUrl +
        // "/api/bien/walletx/" +
        "/api/bien/wallet1/" +
        id_wallet
      // +
      // "/" +
      // pagination +
      // "/" +
      // sortAttribute
    )
    .then((res) => {
      console.log("res.data");
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const filterannonceportefeuille = (
  id_wallet,
  pagination,
  valeur,
  sortAttribute,
  reqstart,
  critere
) => {
  console.log(reqstart, "reqstart");
  console.log(critere, "critere");

  return axios
    .post(
      APIServerUrl +
        "/api/bien/walletx/" +
        id_wallet +
        "/filter/" +
        pagination +
        "/type/" +
        valeur +
        "/sort/" +
        sortAttribute +
        "/" +
        reqstart,
      critere
    )
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const update_user_colors =
  (id, couleurprimaire, couleursecondaire) => (dispatch) => {
    console.log("called");
    return axios
      .post(APIServerUrl + "/api/users/add_user_color", {
        id,
        couleurprimaire,
        couleursecondaire,
      })
      .then((res) => {
        console.log("called");

        dispatch({
          type: UPDATE_USER_COLORS,
          payload: {
            pcolor: couleurprimaire,
            scolor: couleursecondaire,
          },
        });
      })
      .catch((err) => {
        return err;
      });
  };

export const getbiensgestionnaires = (gestionnaires) => {
  return axios.post(APIServerUrl + "/api/bien/dashboard_admin_gestionnaire", {
    gestionnaires,
  });
};

export const getbiensproposee = (gestionnaires) => {
  return axios.post(
    APIServerUrl + "/api/bien/dashboard_admin_gestionnaire/bienproposee",
    { gestionnaires }
  );
};

export const get_biens_wallet = (id_user, pagination, typebien, sort) => {
  return axios.get(
    APIServerUrl +
      "/api/bien/walletz/paginate/" +
      id_user +
      "/" +
      typebien +
      "/" +
      pagination +
      "/" +
      sort
  );
};

export const filter_biens_wallet = (
  id_user,
  pagination,
  sort,
  typebien,
  filter
) => {
  console.log("afilter", sort);
  return axios.post(
    APIServerUrl +
      "/api/bien/walletz/paginate/" +
      id_user +
      "/" +
      pagination +
      "/" +
      sort +
      "/" +
      typebien,
    { filter }
  );
};

export const get_biens_locatif = (pagination, sort, userId) => {
  return axios.get(
    APIServerUrl +
      "/api/bien/paginate/" +
      userId +
      "/" +
      pagination +
      "/" +
      sort
  );
};

export const filter_biens_locatif = (page, sort, userId, filter) => {
  console.log("locatifrecg", sort, filter, "other", page);
  return axios.post(
    APIServerUrl +
      "/api/bien/locatif/paginate/filter/" +
      userId +
      "/" +
      page +
      "/" +
      sort,
    { filter }
  );
};

export const get_nbe_biens_locatif_non_filtrer = () => {
  return axios.get(APIServerUrl + "/api/bien/locatif/nombrebiens/" + 0);
};
export const get_nbe_biens_locatif_filtrer = (filtre) => {
  return axios.post(
    APIServerUrl + "/api/bien/locatif/nombrebiensfiltrer/" + 0,
    { filtre }
  );
};

// export const get_biens_residentiel = (pagination, typebien, sort) => {
//   return axios.get(
//     APIServerUrl +
//       "/api/annonce/residentiel/" +
//       typebien +
//       "/" +
//       pagination +
//       "/" +
//       sort
//   );
// };

export const get_biens_residentiel = (page, sort) => {
  return axios.get(
    APIServerUrl + "/api/annonce/paginate/residentiel/" + page + "/" + sort
  );
};

export const get_biens_residentiel100 = (sort) => {
  return axios.get(APIServerUrl + "/api/annonce/all/" + sort);
};

export const filter_biens_residentiel = (
  pagination,

  sort,
  filter
) => {
  console.log("anbe", filter);
  return axios.post(
    APIServerUrl + "/api/annonce/residentiel/filter/" + pagination + "/" + sort,
    { filter }
  );
};

export const get_nbe_biens_residentiel_non_filtrer = () => {
  return axios.get(APIServerUrl + "/api/annonce/residentiel/nombrebiens/" + 1);
};
export const get_nbe_biens_residentiel_filtrer = (filtre) => {
  return axios.post(
    APIServerUrl + "/api/annonce/residentiel/nombrebiensfiltrer/" + 1,
    { filtre }
  );
};

export const add_plans_to_bien = (id_bien, plans) => {
  return axios.post(APIServerUrl + "/api/bien/addplan/" + id_bien, plans);
};

export const add_plan_to_bien = (id_bien, plans) => {
  return axios.post(APIServerUrl + "/api/bien/addplans/" + id_bien, plans);
};

export const add_photo_to_bien = (id_bien, plans) => {
  return axios.post(APIServerUrl + "/api/bien/addphoto/" + id_bien, plans);
};

export const get_notaire_data = (data) => {
  return axios.post(APIServerUrl + "/api/notaire/", data);
};

export const get_bien_from_wallet = (id_wallet, id_user) => {
  return axios.get(
    APIServerUrl + "/api/bien/inwallet/" + id_wallet + "/" + id_user
  );
};

export const get_bien_from_walletpublic = (id_wallet) => {
  return axios.get(
    APIServerUrl + "/api/bien/fromwallet/" + id_wallet 
  );
};
